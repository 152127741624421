import axios from 'axios'
import { IUser, User, UserRole } from '../model/user.model'
import store from '../../store'
import router from '../../router'

class UserService {
  getMyself () {
    store.commit('authenticate')

    return axios
      .get('/api/myself')
      .then(response => {
        const account = response.data
        if (account) {
          store.commit('authenticated', account)
        } else {
          store.commit('logout')
          router.push('/login', () => { })
        }
      })
      .catch(e => {
        store.commit('logout')
        // console.error('getUser() failed', e)
      })
  }

  dateIsOlderThan10Years (date: Date) {
    const now = new Date()
    const date10YearsAgo = new Date()
    date10YearsAgo.setFullYear(now.getFullYear() - 10)

    const birthday = new Date(date)

    if (isNaN(birthday.getTime())) return false

    return birthday.toISOString() < date10YearsAgo.toISOString()
  }

  userDetailsAreComplete (user: IUser) {
    let userDetailsComplete = true
    if (user.metadata) {
      if (!user.metadata.birthday || !this.dateIsOlderThan10Years(user.metadata.birthday)) {
        userDetailsComplete = false
      }

      if (user.metadata.address) {
        const userDataComplete =
          !!user.metadata.address.street &&
          !!user.metadata.address.city &&
          !!user.metadata.address.zip &&
          !!user.metadata.address.country
        if (!userDataComplete) {
          userDetailsComplete = false
        }
      }
    }
    return userDetailsComplete
  }

  getAllUsersPaginated (query: any) {
    store.dispatch('userStore/getList', query)
  }

  get account () {
    return store.getters.account
  }

  userIsSystemAdmin () {
    if (this.account && this.account.roles.includes(UserRole.admin)) {
      return true
    }
    return false
  }

  createUser (user: IUser) {
    return axios.post('/api/user', user).catch(e => {
      console.error('createUser() failed', e)
    })
  }

  signupUser (user: IUser) {
    return axios.post('/api/user/signup', user).catch(e => {
      console.error('signup() failed', e)
    })
  }

  resetPasswordConfirm (token: string) {
    return axios.post(`/api/reset_password/${token}`).catch(e => {
      console.error('resetPassword() failed', e)
    })
  }

  getUser (id: any): Promise<IUser> {
    return new Promise((resolve, reject) => {
      axios.get(`/api/user/${id}`).then(result => {
        if (result.status === 200 && result.data) {
          const user: IUser = result.data
          resolve(user)
        } else {
          console.error(`User with id: ${id} not found`)
          resolve(new User())
        }
      })
    })
  }

  getCoworkerProfile (uuid: any): Promise<IUser> {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/coworker/${uuid}`)
        .then(result => {
          if (result.status === 200 && result.data) {
            const user: IUser = result.data
            resolve(user)
          } else {
            console.error(`Coworker Profile with id: ${uuid} not found`)
            resolve(new User())
          }
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  getPublicProfile (uuid: any): Promise<IUser> {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/public/${uuid}`)
        .then(result => {
          if (result.status === 200 && result.data) {
            const user: IUser = result.data
            resolve(user)
          } else {
            console.error(`Public Profile with id: ${uuid} not found`)
            resolve(new User())
          }
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  updateUser (user: IUser) {
    return axios.put(`/api/user/${user._id}`, user).catch(e => {
      console.error('updateUser() failed', e)
    })
  }

  deleteUser (id: string) {
    return axios.delete(`/api/user/${id}`).catch(e => {
      console.error('deleteUser() failed', e)
    })
  }

  checkVersion () {
    const VERSION = 'latestFetchedVersion'
    return axios
      .get('/api/version')
      .then(response => {
        if (response && response.data && response.data.version) {
          const currentVersion: any = response.data.version
          const latestFetchedVersion: any = localStorage.getItem(VERSION)

          if (latestFetchedVersion !== null) {
            if (latestFetchedVersion !== currentVersion) {
              localStorage.setItem(VERSION, currentVersion)
              location.reload()
            }
          } else {
            localStorage.setItem(VERSION, currentVersion)
          }
        }
      })
      .catch(e => {
        console.error('updateUser() failed', e)
      })
  }

  checkUserProfileIsComplete () {
    if (!sessionStorage.getItem('redirectedToCompleteUserProfile') && !this.userDetailsAreComplete(this.account)) {
      router.push({ path: '/app/user', query: { message: 'incomplete' } })
      sessionStorage.setItem('redirectedToCompleteUserProfile', 'true')
    }
  }

  getUserImage (id: string) {
    return axios.get(`/api/file/${id}`).catch(e => {
      console.error('getUserImage() failed', e)
      return e.response
    })
  }

  checkIfUserWithEmailAlreadyExists (email: string) {
    return axios.get(`/api/checkemail/${email}`).catch(e => {
      console.error('User with that email already exists', e)
      return e.response
    })
  }

  checkIfUsernameExists (username: string) {
    return axios.get(`/api/checkusername/${username}`).catch(e => {
      console.error('Username already exists', e)
      return e.response
    })
  }

  getUserByEvalId (evalid: string) {
    return axios.get(`/api/userbyevalid/${evalid}`)
  }

  async canCreateReports () {
    const result = await axios.get('/api/myselfCanCreateReports')
    if (result.data) {
      return result.data.canCreateReports
    }
    return false
  }
}

const userService = new UserService()
export default userService
