import Vue from 'vue'
import Router from 'vue-router'
import authService from './authService'
import store from './store'
import userService from './shared/services/userService'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/pages/Index.vue'),
      children: [
        {
          name: 'Homepage',
          path: '',
          redirect: '/login',
          component: () => import('@/views/pages/Homepage.vue'),
        },
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/views/pages/Login.vue'),
        },
        {
          name: 'Register',
          path: 'register',
          component: () => import('@/views/pages/Register.vue'),
        },
        {
          name: 'Confirm password reset',
          path: 'confirm-password-reset/:resetId',
          component: () => import('@/views/pages/ConfirmPasswordReset.vue'),
        },
        {
          name: 'Public Profile',
          path: 'public/:uuid',
          component: () => import('@/views/pages/PublicProfile.vue'),
        },
        {
          name: 'Accept company invite',
          path: 'acceptCompanyInvitation/:token',
          component: () => import('@/views/pages/CompanyInvitation.vue'),
        },
        {
          name: 'Forbidden',
          path: 'forbidden',
          component: () => import('@/views/pages/Forbidden.vue'),
        },
      ],
    },
    {
      path: '/app',
      component: () => import('@/views/app/Index.vue'),
      children: [
        {
          name: 'dashboard',
          path: '',
          component: () => import('@/views/app/Dashboard.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'user-profile',
          path: 'user',
          component: () => import('@/views/app/user/UserProfile.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'change-password',
          path: 'change-password',
          component: () => import('@/views/app/user/ChangePassword.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'edit-user-profile',
          path: 'users/:uuid',
          component: () => import('@/views/app/user/UserProfile.vue'),
          meta: { authorities: ['admin'] },
        },
        {
          name: 'edit-user-profile-admin',
          path: 'user/:uuid',
          component: () => import('@/views/app/user/UserProfile.vue'),
          meta: { authorities: ['admin'] },
        },
        {
          name: 'users',
          path: 'users',
          component: () => import('@/views/app/user/DataTables.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'coworker',
          path: 'coworker',
          component: () => import('@/views/app/company/CoworkerTable.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'coworker-profile',
          path: 'coworker/:uuid',
          component: () => import('@/views/app/company/CoworkerProfile.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'companies',
          path: 'companies',
          component: () => import('@/views/app/company/CompanyDataTables.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'certification-authorities',
          path: 'certification-authorities',
          component: () => import('@/views/app/certificationAuthority/CertificationAuthoritiesTable.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'certification-authority',
          path: 'certification-authority',
          component: () => import('@/views/app/certificationAuthority/CertificationAuthorityProfile.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'certification-authority-admin',
          path: 'certification-authorities/:id',
          component: () => import('@/views/app/certificationAuthority/CertificationAuthorityProfile.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'certificates',
          path: 'certificates',
          component: () => import('@/views/app/certificate/CertificatesTable.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'certificate',
          path: 'certificate/new',
          component: () => import('@/views/app/certificate/CertificateWizard.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'company-profile',
          path: 'company-profile',
          component: () => import('@/views/app/company/CompanyProfile.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'company-profile-admin',
          path: 'companies/:id',
          component: () => import('@/views/app/company/CompanyProfile.vue'),
          meta: { authorities: ['admin'] },
        },
        {
          name: 'my-vehicles',
          path: 'company-vehicles',
          component: () => import('@/views/app/vehicle/CompanyVehiclesTable.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'my-vehicle',
          path: 'company-vehicles/:id',
          component: () => import('@/views/app/vehicle/CompanyVehiclesTable.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'reports',
          path: 'reports',
          component: () => import('@/views/app/report/ReportsTable.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'new-report',
          path: 'reports/new',
          component: () => import('@/views/app/report/ReportWizard.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'report',
          path: 'reports/:rid',
          component: () => import('@/views/app/report/ReportsTable.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'new-report',
          path: 'reports/new/:companyVehicleId',
          component: () => import('@/views/app/report/ReportWizard.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'vehicles',
          path: 'vehicle',
          component: () => import('@/views/app/vehicle/VehiclesTables.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'vehicle-details',
          path: 'vehicle/:id',
          component: () => import('@/views/app/vehicle/VehicleDetails.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'vehicles-admin',
          path: 'vehicles',
          component: () => import('@/views/app/vehicle/VehiclesTables.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'local-storage-reset',
          path: 'local-storage-reset',
          component: () => import('@/views/app/LocalStorageReset.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'all-reports',
          path: 'all-reports',
          component: () => import('@/views/app/report/ReportsTable.vue'),
          meta: { authorities: ['admin'] },
        },
        {
          name: 'info-signs',
          path: 'signs',
          component: () => import('@/views/app/Signs.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'forum',
          path: 'forum',
          component: () => import('@/views/app/forum/ForumTable.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'topic',
          path: 'forum/:id',
          component: () => import('@/views/app/forum/ForumTopic.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
      ],
    },
    {
      path: '/pdf',
      component: () => import('@/views/pdf/Index.vue'),
      children: [
        {
          name: 'report-pdf',
          path: 'report/:rid',
          component: () => import('@/views/pdf/ReportPdfDetails.vue'),
        },
        {
          name: 'certificate-pdf',
          path: 'certificate/:id',
          component: () => import('@/views/pdf/CertificatePdf.vue'),
        },
        {
          name: 'webviewer-training-data',
          path: 'webviewer/:id',
          component: () => import('@/views/pdf/WebViewer.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'webviewer',
          path: 'webviewer/doc/:documentId',
          component: () => import('@/views/pdf/WebViewer.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
        {
          name: 'sign',
          path: 'sign',
          component: () => import('@/views/pdf/Sign.vue'),
          meta: { authorities: ['user', 'admin'] },
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/pages/Index.vue'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/pages/Error.vue'),
        },
      ],
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  if (!to.matched.length) {
    next('/404')
  }

  await userService.getMyself()

  if (store.getters.authenticated) {
    if (to.path.includes('/login') || to.path.includes('/register')) {
      router.push('/app', () => {})
    }
  }

  if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
    authService.hasAnyRole(to.meta.authorities).then(value => {
      if (!value) {
        if (authService.userIdentity) {
          next('/forbidden')
        } else {
          next('/login')
        }
      } else {
        next()
      }
    })
  } else {
    // no authorities, so just proceed
    next()
  }
})

export default router
