import Vue from 'vue'
import Vuex from 'vuex'

import userStore from './modules/user'
import vehicleStore from './modules/vehicle.store'
import vehicleManufacturerStore from './modules/vehicleManufacturer.store'
import companyStore from './modules/company'
import companyVehicleStore from './modules/companyVehicle.store'
import reportStore from './modules/report.store'
import certificationAuthorityStore from './modules/certificationAuthority.store'
import certificateStore from './modules/certificate.store'
import certificateTemplateStore from './modules/certificateTemplate.store copy'

Vue.use(Vuex)

const authStore = {
  state: {
    logon: false,
    account: null,
    certificationAuthorities: null,
    selectedCertificationAuthority: null,
    authenticated: false,
    drawer: null,
  },
  getters: {
    logon: (state: any) => state.logon,
    account: (state: any) => state.account,
    certificationAuthorities: (state: any) => state.certificationAuthorities,
    selectedCertificationAuthority: (state: any) => state.selectedCertificationAuthority,
    authenticated: (state: any) => state.authenticated,
    drawer: (state: any) => state.drawer,
  },
  mutations: {
    authenticate (state: any) {
      state.logon = true
    },
    authenticated (state: any, account: any) {
      state.account = account
      state.authenticated = true
      state.logon = false
    },
    logout (state: any) {
      state.account = null
      state.authenticated = false
      state.logon = false
    },
    SET_DRAWER (state: any, payload: any) {
      state.drawer = payload
    },
    setCertificationAuthorities (state: any, payload: any) {
      state.certificationAuthorities = payload
    },
    setSelectedCertificationAuthority (state: any, payload: any) {
      state.selectedCertificationAuthority = payload
    },
    resetMain (state: any) {
      state.logon = false
      state.account = null
      state.certificationAuthority = null
      state.selectedCertificationAuthority = null
      state.authenticated = false
      state.drawer = null
    },
  },
}

const store = new Vuex.Store({
  modules: {
    authStore,
    userStore,
    companyStore,
    vehicleStore,
    vehicleManufacturerStore,
    companyVehicleStore,
    reportStore,
    certificationAuthorityStore,
    certificateStore,
    certificateTemplateStore,
  },
})

export default store
