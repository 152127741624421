import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from 'vuetify/src/locale/en'
import de from 'vuetify/src/locale/de'
import it from 'vuetify/src/locale/it'

import { localize } from 'vee-validate'
import validationMessages from 'vee-validate/dist/locale/de.json'
import validationMessagesEN from 'vee-validate/dist/locale/en.json'
import validationMessagesIT from 'vee-validate/dist/locale/it.json'

Vue.use(VueI18n)

const messages = {
  en: {
    ...require('@/locales/en.json'),
    $vuetify: en,
  },
  de: {
    ...require('@/locales/de.json'),
    $vuetify: de,
  },
  it: {
    ...require('@/locales/it.json'),
    $vuetify: it,
  },
}

// VeeValidate
// if we add more languages in the future we should wrap that inside a function

localize('de', validationMessages)

export function changeLang (lang: string) {
  if (lang === 'de') {
    localize('de', validationMessages)
  } else if (lang === 'en') {
    localize('de', validationMessagesEN)
  } else if (lang === 'it') {
    localize('de', validationMessagesIT)
  }
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'de',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
  messages,
})
