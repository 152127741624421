import Vue from 'vue'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  email,
  max,
  min,
  // eslint-disable-next-line camelcase
  min_value,
  numeric,
  required,
} from 'vee-validate/dist/rules'
import userService from '@/shared/services/userService'
import { regexEUNumber, regexNumbersAndSpecialChars } from '@/shared/helpers'

extend('email', email)
extend('max', max)
extend('min', min)
extend('numeric', numeric)
extend('required', required)
extend('min_value', min_value)

extend('checkusername', async (value) => {
  const usernameCheck: any = await userService.checkIfUsernameExists(value)

  return usernameCheck.status === 200 ? true : 'dialog.username-is-already-used'
})

extend('checkemail', async (value, values) => {
  // info: '.join()' was the only method to satisfy the linter and typescript so far 
  if (values && value === values.join('')) {
    // the user that is being edited owns this email so don't show error
    return true
  } else {
    const emailCheck: any = await userService.checkIfUserWithEmailAlreadyExists(value)
    return emailCheck.status === 200 ? true : 'dialog.email-is-already-used'
  }
})

extend('checkconsent', async (value) => {
  return value ? true : 'dialog.required-to-complete-registration'
})

extend('birthday', async (value) => {
  const userAgeCheck: boolean = await userService.dateIsOlderThan10Years(value)

  return userAgeCheck ? true : 'dialog.enter-valid-birthday-date'
})

// https://stackoverflow.com/questions/59156440/how-to-validate-decimal-value-in-vee-validate-3-0-version
extend('decimal', async (value) => {
  return regexEUNumber(value) ? true : 'dialog.enter-valid-eu-number-format'
})

extend('constructionyear', async (value) => {
  return regexNumbersAndSpecialChars(value) ? true : 'dialog.only-use-numbers-with-or'
})

Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
