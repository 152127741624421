import { render, staticRenderFns } from "./ItemGroup.vue?vue&type=template&id=37ee60d4&"
import script from "./ItemGroup.vue?vue&type=script&lang=js&"
export * from "./ItemGroup.vue?vue&type=script&lang=js&"
import style0 from "./ItemGroup.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VImg,VListGroup,VListItemAvatar,VListItemContent,VListItemIcon,VListItemTitle})
