import Vue from 'vue'
import Vuetify, { VBtn, VIcon, VSnackbar } from 'vuetify/lib'
// import i18n from '@/i18n'
import '@/sass/overrides.sass'
import en from 'vuetify/src/locale/en'
import de from 'vuetify/src/locale/de'
import VuetifyToast from 'vuetify-toast-snackbar'

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
  },
})

const theme = {
  primary: '#FF7A59',
  secondary: '#FF7A59',
  accent: '#9C27b0',
  info: '#00CAE3',
  indigo: '#7f7f7f',
  error: '#db3236',
}

const VuetifyObj = new Vuetify({
  lang: {
    // t: (key, ...params) => i18n.t(key, params),
    locales: { de, en },
    current: 'de',
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})

Vue.use(VuetifyToast, {
  $vuetify: VuetifyObj.framework,
  x: 'center',
  y: 'bottom',
  color: 'darkgrey',
  icon: 'mdi-alert-circle-outline',
  iconColor: '', // default
  classes: ['flex-snackbar'],
  timeout: 3000, // default
  dismissable: true, // default
  multiLine: false, // default
  vertical: false, // default
  queueable: false, // default
  showClose: true,
  closeText: '',
  closeIcon: 'mdi-check',
  closeColor: 'success',
  slot: [], // default
  shorts: {
    error: {
      closeIcon: 'mdi-close',
      closeColor: 'error',
    },
  },
  property: '$toast', // default
})

export default VuetifyObj
