export enum UserRole {
  user = 'user',
  admin = 'admin',
}
export interface IUser {
  _id?: string
  username?: string
  password?: string
  generatedPassword?: string
  email?: string
  language?: string
  roles?: UserRole[]
  metadata: {
    firstName?: string
    lastName?: string
    birthday?: Date
    phone?: string
    address?: {
      street?: string
      zip?: string
      city?: string
      country?: string
    }
    image?: string | null | undefined
    signature?: string | null | undefined
  }
  activationEmailSent?: Boolean
  activated?: Boolean
  lastLogin?: Date
  lastActivity?: Date
  createdAt?: Date
  updatedAt?: Date
  evalid?: string
  uuid?: string
}

export class User implements IUser {
  constructor (
    public _id?: string,
    public username?: string,
    public password?: string,
    public generatedPassword?: string,
    public email?: string,
    public language?: string,
    public roles?: UserRole[],
    public metadata: {
      firstName?: string
      lastName?: string
      birthday?: Date
      phone?: string
      address?: {
        street?: string
        zip?: string
        city?: string
        country?: string
      }
      image?: string | null | undefined
      signature?: string | null | undefined
      public?: {}
    } = {},
    public activationEmailSent?: Boolean,
    public activated?: Boolean,
    public lastLogin?: Date,
    public lastActivity?: Date,
    public createdAt?: Date,
    public updatedAt?: Date,
    public evalid?: string,
    public uuid?: string,
  ) {
    this.metadata = {}
    this.metadata.public = {}
    this.metadata.address = { street: '' }
    this.roles = [UserRole.user]
    this.activationEmailSent = false
    // return this
  }
}
