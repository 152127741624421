import store from '@/store'
import Vue from 'vue'

const waitForStoreMutation = {
  install (Vue: { prototype: { $waitForStoreMutation: (mutationType: string) => Promise<any> } }) {
    Vue.prototype.$waitForStoreMutation = (mutationType: string) => {
      return new Promise((resolve) => {
        const unsubscribe = store.subscribe((mutation) => {
          if (mutation.type === mutationType) {
            resolve(mutation.payload)
            unsubscribe()
          }
        })
      })
    }
  },
}
Vue.use(waitForStoreMutation)

declare module 'vue/types/vue' {
  // Declare the type so we can use it in 'ts' (see AppBar)
  interface Vue {
    $waitForStoreMutation: (mutationType: string) => Promise<any>
  }
}
