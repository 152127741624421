// =========================================================
// * Vuetify Material Dashboard PRO - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import './plugins/base'
import './plugins/vee-validate'
import './plugins/vue-world-map'
import './plugins/globalFunctions'
import vuetify from './plugins/vuetify'
import i18n from './i18n'

import axios from 'axios'
import VueAxios from 'vue-axios'

import VuetifyConfirm from 'vuetify-confirm'

import * as Sentry from '@sentry/vue'

import * as filters from './plugins/filters'
import { Integrations } from '@sentry/tracing'

Vue.use(VueAxios, axios)
Vue.use(VuetifyConfirm, {
  vuetify,
})

Vue.config.productionTip = false

if (!window.location.href.includes('localhost')) {
 Sentry.init({
    Vue,
    environment: process.env.NODE_ENV,
    dsn: 'https://b9672cba24d24486bda6f2d2f272682d@sentry.seadev-studios.com/2',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'my-site-url.com', /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // Recommendations for prod are 0.2-0.5
    tracesSampleRate: 0.2,
  })
}

filters.initFilters()

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
