import { createAsyncType } from '@/store/utils/mutation-types'
import axios from 'axios'
import findIndex from 'lodash/findIndex'

export const types = {
  RESET: 'RESET',
  CHANGE_PAGE: 'CHANGE_PAGE',
  GET_LIST: createAsyncType('GET_LIST'),
  GET_ITEM: createAsyncType('GET_ITEM'),
  UPDATE_ITEM: createAsyncType('UPDATE_ITEM'),
  CREATE_ITEM: createAsyncType('CREATE_ITEM'),
  DELETE_ITEM: createAsyncType('DELETE_ITEM'),
}

const PAGINATION_CURRENT_PAGE = 1
const PAGINATION_PER_PAGE = 10
const PAGINATION_TOTAL = 0

export const getDefaultState = (options: any) => ({
  items: [],
  loading: false,
  descending: false,
  sortBy: null,
  page: PAGINATION_CURRENT_PAGE,
  currentPage: PAGINATION_CURRENT_PAGE,
  perPage: options.perPage || PAGINATION_PER_PAGE,
  total: PAGINATION_TOTAL,
  search: {},
  error: null,
})

export const createResource = (resource: any, options = {}) => ({
  namespaced: true,

  state: getDefaultState(options),

  mutations: {
    [types.RESET] (state: any) {
      Object.assign(state, getDefaultState(options))
    },
    [types.CHANGE_PAGE] (state: any, params: any) {
      state.page = params.page
    },
    [types.GET_LIST.REQUEST] (state: any, params: any) {
      state.loading = true
      state.currentPage = params.page
      state.search = params.search
    },
    [types.GET_LIST.SUCCESS] (state: any, result: any) {
      state.items = result.data.items
      state.total = result.data.total
      state.loading = false
    },
    [types.GET_LIST.FAILURE] (state: any) {
      state.loading = false
    },
    [types.GET_ITEM.REQUEST] (state: any, params: any) {
      state.loading = true
    },
    [types.GET_ITEM.SUCCESS] (state: any, result: any) {
      state.items = [result.data]
      state.total = 1
      state.loading = false
    },
    [types.GET_ITEM.FAILURE] (state: any) {
      state.loading = false
    },
    [types.UPDATE_ITEM.REQUEST] (state: any) {
      state.error = null
      state.loading = true
    },
    [types.UPDATE_ITEM.SUCCESS] (state: any, result: any) {
      const indexOfItem = findIndex(
        state.items,
        (item: any) => item._id === result.data._id,
      )
      if (indexOfItem !== -1) {
        state.items[indexOfItem] = result.data
      }
      state.loading = false
    },
    [types.UPDATE_ITEM.FAILURE] (state: any, error: any) {
      state.error = error
      state.loading = false
    },
    [types.CREATE_ITEM.REQUEST] (state: any) {
      state.error = null
      state.loading = true
    },
    [types.CREATE_ITEM.SUCCESS] (state: any, result: any) {
      state.items.push(result.data)
      state.loading = false
    },
    [types.CREATE_ITEM.FAILURE] (state: any, error: any) {
      state.error = error
      state.loading = false
    },
    [types.DELETE_ITEM.REQUEST] (state: any) {
      state.loading = true
    },
    [types.DELETE_ITEM.SUCCESS] (state: any, result: any) {
      state.loading = false
    },
    [types.DELETE_ITEM.FAILURE] (state: any) {
      state.loading = false
    },
  },

  actions: {
    async changePage ({ commit }: any, page: any) {
      commit(types.CHANGE_PAGE, { page })
    },

    async getList ({ commit }: any, { l, lo, sf, so, day, search = {} }: any) {
      commit(types.GET_LIST.REQUEST, { l, lo, search })
      try {
        // The actual limitOffset is calculated in the api
        const result = await axios.get(`/api/${resource}`, {
          params: { l: l, lo: lo - 1, sf, so, day, ...search },
        })
        commit(types.GET_LIST.SUCCESS, result)
      } catch (err) {
        commit(types.GET_LIST.FAILURE)
      } finally {
      }
    },

    async getItem ({ commit }: any, { id }: any) {
      commit(types.GET_ITEM.REQUEST, { id })
      try {
        commit(
          types.GET_ITEM.SUCCESS,
          await axios.get(`/api/${resource}/${id}`, {}),
        )
      } catch (err) {
        commit(types.GET_ITEM.FAILURE)
      } finally {
      }
    },

    async updateItem ({ commit }: any, { id, item }: any) {
      commit(types.UPDATE_ITEM.REQUEST)
      try {
        commit(
          types.UPDATE_ITEM.SUCCESS,
          await axios.put(`/api/${resource}/${id}`, item),
        )
      } catch (e) {
        commit(types.UPDATE_ITEM.FAILURE, e)
      }
    },

    async createItem ({ commit }: any, { item }: any) {
      commit(types.CREATE_ITEM.REQUEST)
      try {
        commit(
          types.CREATE_ITEM.SUCCESS,
          await axios.post(`/api/${resource}`, item),
        )
      } catch (e) {
        commit(types.CREATE_ITEM.FAILURE, e)
      }
    },

    async deleteItem ({ commit }: any, { id, item }: any) {
      commit(types.DELETE_ITEM.REQUEST)
      try {
        commit(
          types.DELETE_ITEM.SUCCESS,
          await axios.delete(`/api/${resource}/${id}`),
        )
      } catch (e) {
        commit(types.DELETE_ITEM.FAILURE)
      }
    },

    reset ({ commit }: any) {
      commit(types.RESET)
    },
  },

  getters: {
    getById: (state: any) => (id: any) => {
      return state.items.find((o: any) => o._id === id)
    },
    getList: (state: any) => {
      return state.items
    },
    getTotal: (state: any) => {
      return state.total
    },
    getError: (state: any) => {
      return state.error
    },
  },
})
