// copied from https://codesandbox.io/s/n9lrp9mx2p?file=/src/App.vue
export function debounceInput (debounce: number | undefined, callback: any, timeout: number = 400) {
  clearTimeout(debounce)
  return setTimeout(() => {
    callback()
  }, timeout)
}

// copied from https://gist.github.com/davalapar/d0a5ba7cce4bc599f54800da22926da2
export function downloadFile (data: any, filename: any, mime: any) {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const blob = new Blob([data], {
    type: mime || 'application/octet-stream',
  })
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE doesn't allow using a blob object directly as link href.
    // Workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename)
    return
  }
  // Other browsers
  // Create a link pointing to the ObjectURL containing the blob
  const blobURL = window.URL.createObjectURL(blob)
  const tempLink = document.createElement('a')
  tempLink.style.display = 'none'
  tempLink.href = blobURL
  tempLink.setAttribute('download', filename)
  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank')
  }
  document.body.appendChild(tempLink)
  tempLink.click()
  document.body.removeChild(tempLink)
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(blobURL)
  }, 100)
}

export function downloadFileURL (url: string, filename: string, mime: string) {
  const tempLink = document.createElement('a')
  tempLink.style.display = 'none'
  tempLink.href = url
  tempLink.setAttribute('download', filename)
  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank')
  }
  document.body.appendChild(tempLink)
  tempLink.click()
  document.body.removeChild(tempLink)
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(url)
  }, 100)
}

export function screenSizeIsMobile () {
  return screen.width < 800
}

export function isMobileScreenWidth () {
  return window.innerWidth < 800
}

export function regexEUNumber (value: string) {
  const decimals = '*'
  const separator = ','
  if (value === null || value === undefined || value === '') {
    return {
      valid: false,
    }
  }
  if (Number(decimals) === 0) {
    return {
      valid: /^-?\d*$/.test(value),
    }
  }
  const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
  const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`)

  return regex.test(value)
}

export function regexNumbersAndSpecialChars (value: string) {
  if (value === null || value === undefined || value === '') {
    return {
      valid: false,
    }
  }
  // eslint-disable-next-line no-useless-escape
  const regex = new RegExp('^[0-9-/]*$')

  return regex.test(value)
}

function base64Resize (sourceBase64: any, maxWidth: any) {
  return new Promise<string>(function (resolve, reject) {
    const img = document.createElement('img')
    img.setAttribute('src', sourceBase64)

    img.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height

      const ctx = canvas.getContext('2d')
      // var cw = canvas.width
      // var ch = canvas.height

      let maxW = img.width
      if (maxWidth < img.width) {
        maxW = maxWidth
      }
      const maxH = img.height

      const iw = img.width
      const ih = img.height
      const scale = Math.min(maxW / iw, maxH / ih)
      const iwScaled = iw * scale
      const ihScaled = ih * scale
      canvas.width = iwScaled
      canvas.height = ihScaled

      if (ctx) {
        // Fill with white
        ctx.fillStyle = '#FFF'
        ctx.fillRect(0, 0, canvas.width, canvas.height)

        ctx.drawImage(img, 0, 0, iwScaled, ihScaled)
      }
      // 0.7 is the default jpeg quality almost everywhere
      // if you use more, you might end up increasing the fileSize
      const quality = 0.7
      const newBase64 = canvas.toDataURL('image/jpeg', quality)

      // console.log(newBase64)
      // console.log(this.byteSize(newBase64))

      resolve(newBase64)
    }
  })
}

export function imgToBase64 (file: File) {
  return new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader()

    fileReader.onload = async (e: any) => {
      const base64Data = e.target.result
      const result = await base64Resize(base64Data, 1000)

      resolve(result)
    }
    fileReader.readAsDataURL(file)
  })
}