import i18n from '@/i18n';
import { IUser } from '@/shared/model/user.model'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/de'
import 'dayjs/locale/it'
import Vue from 'vue'


export const DATE_FORMAT = 'YYYY-MM-DD'

dayjs.extend(relativeTime)

export function initFilters () {
  Vue.filter('formatDate', (value: any) => {
    if (value) {
      return dayjs(value).format(DATE_FORMAT)
    }
    return ''
  })
  Vue.filter('formatDateForTable', (value: any) => {
    if (value) {
      return dayjs(value).format('DD.MM.YYYY')
    }
    return ''
  })
  Vue.filter('formatDateForReport', (value: any) => {
    if (value) {
      return dayjs(value).format('DD.MM.YYYY HH:mm')
    }
    return ''
  })
  Vue.filter('formatDateForTopics', (value: any) => {
    if (value) {
      dayjs.locale(i18n.locale)

      return dayjs(value).fromNow()
    }
    return ''
  })
  Vue.filter('fullNameOfUser', (user: IUser) => {
    if (user && user.metadata.firstName && user.metadata.lastName) {
      return user.metadata.firstName + ' ' + user.metadata.lastName
    }
    return ''
  })
}
